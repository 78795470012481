import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  icons,
  container,
  iconContainer,
  mainContainer,
  description,
} from "./description.module.css";

function Description() {
  const data = useStaticQuery(graphql`
    query {
      contentfulAvantages {
        avantages
        nosAvantages
        description {
          description
        }
      }
    }
  `);

  return (
    <div className={mainContainer}>
      <h1 className="left">{data.contentfulAvantages.nosAvantages}</h1>
      <div className={description}>
        <p>{data.contentfulAvantages.description.description}</p>
      </div>
      <div className={container}>
        <div className={iconContainer}>
          <div className={icons}>
            <DescriptionIcon fontSize="large" color="info" />
          </div>
          <p>{data.contentfulAvantages.avantages[0]}</p>
        </div>

        <div className={iconContainer}>
          <div className={icons}>
            <PersonIcon fontSize="large" color="info" />
          </div>

          <p>{data.contentfulAvantages.avantages[1]}</p>
        </div>

        <div className={iconContainer}>
          <div className={icons}>
            <HandshakeIcon fontSize="large" color="info" />
          </div>

          <p>{data.contentfulAvantages.avantages[2]}</p>
        </div>

        <div className={iconContainer}>
          <div className={icons}>
            <ThumbUpIcon fontSize="large" color="info" />
          </div>

          <p>{data.contentfulAvantages.avantages[3]}</p>
        </div>
      </div>
    </div>
  );
}

export default Description;
